import "react-tabs/style/react-tabs.css";
import "./all.sass";
import "./style.css";

import getFirebase, { FirebaseContext } from "./Firebase";

import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import React from "react";
import useSiteMetadata from "./SiteMetadata";
import withAuthentication from "./Session/withAuthentication";
import { withPrefix } from "gatsby";
import GuestPromoSignUp from "./GuestPromoSignUp";
import useMobileAppCheck from "../hooks/useMobileAppCheck";

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  const [firebase, setFirebase] = React.useState(null);

  React.useEffect(() => {
    const app = import("firebase/app");
    const auth = import("firebase/auth");
    const database = import("firebase/firestore");

    Promise.all([app, auth, database]).then((values) => {
      const firebase = getFirebase(values[0]);

      setFirebase(firebase);
    });
  }, []);

  return (
    <FirebaseContext.Provider value={firebase}>
      <AppWithAuthentication title={title} description={description}>
        {children}
      </AppWithAuthentication>
    </FirebaseContext.Provider>
  );
};

const AppWithAuthentication = withAuthentication(
  ({ title, description, children }) => {
    const isInApp = useMobileAppCheck();
    return (
      <div>
        <Helmet>
          <html lang="en" />
          <title>{title}</title>
          <meta name="description" content={description} />

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${withPrefix("/")}img/apple-touch-icon.png`}
          />
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix("/")}img/favicon-32x32.png`}
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix("/")}img/favicon-16x16.png`}
            sizes="16x16"
          />

          <link
            rel="mask-icon"
            href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
            color="#ff4400"
          />
          <meta name="theme-color" content="#fff" />

          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={title} />
          <meta property="og:url" content="/" />
          <meta
            name="facebook-domain-verification"
            content="0ptswidb00jxmjlyy84fkn9z2y6lav"
          />
          <meta
            name="p:domain_verify"
            content="90c5b78a436efbfaf7163b72e66d2e99"
          />
        </Helmet>
        <div>
          {isInApp === false && (
            <>
              {/* <GuestPromoSignUp /> */}
              <Navbar />
            </>
          )}
        </div>
        <div>{children}</div>
      </div>
    );
  }
);

export default TemplateWrapper;
