import * as React from "react";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import useClientSide from "./useClientSide";

export default function useMobileAppCheck() {
  const isClient = useClientSide();
  const location = useLocation();
  const [isInApp, setIsInApp] = React.useState(false);

  React.useEffect(() => {
    if (isClient) {
      const { mobileApp } = queryString.parse(location.search);
      if (mobileApp !== undefined) setIsInApp(true);
    }
  }, [isClient, location]);

  return isInApp;
}
