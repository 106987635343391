import * as ROLES from "../constants/roles";
import * as ROUTES from "../constants/routes";

import { AuthUserContext } from "./Session";
import { Link } from "gatsby";

import React from "react";
import { FaVideo } from "@react-icons/all-files/fa/FaVideo";
import logo from "../img/kl-logo-without-text-black.png";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Kristie Lengel">
              <img src={logo} alt="Kristie Lengel" />
            </Link>
            {/* Hamburger menu */}
            <AuthUserContext.Consumer>
              {(authUser) =>
                authUser ? (
                  <a
                    className="navbar-item"
                    href="https://calendly.com/kristielengel/talk-to-kristie"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaVideo style={{ marginRight: 2 }} />{" "}
                    <strong>Talk to Kristie</strong>
                  </a>
                ) : (
                  <Link className="navbar-item" to={ROUTES.SIGN_UP}>
                    <strong>Sign Up</strong>
                  </Link>
                )
              }
            </AuthUserContext.Consumer>
            {/* eslint-disable-next-line */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <AuthUserContext.Consumer>
              {(authUser) =>
                authUser ? (
                  <NavigationAuth authUser={authUser} />
                ) : (
                  <NavigationNonAuth />
                )
              }
            </AuthUserContext.Consumer>
          </div>
        </div>
      </nav>
    );
  }
};

const standardLinks = (
  <>
    <Link className="navbar-item" to="/">
      Home
    </Link>
    <Link className="navbar-item" to={ROUTES.BALANCE_BOOT_CAMP}>
      FREE E-Course!
    </Link>
    <Link className="navbar-item" to={ROUTES.FOREVER_BALANCE}>
      1-on-1 Coaching 💥
    </Link>
    <Link className="navbar-item" to={ROUTES.BALANCE_PODCAST}>
      #Balance Podcast
    </Link>
    <Link className="navbar-item" to={ROUTES.BLOG}>
      Blog/Recipes
    </Link>
  </>
);

const NavigationAuth = ({ authUser }) => {
  return (
    <>
      <div className="navbar-start has-text-centered">
        <Link className="navbar-item" to="/">
          Home
        </Link>
        <Link className="navbar-item" to={ROUTES.FOREVER_BALANCE}>
          1-on-1 Coaching 💥
        </Link>
        <Link className="navbar-item" to={ROUTES.TRAINING_VIDEOS}>
          Video Collection
        </Link>
        <Link className="navbar-item" to={ROUTES.BALANCE_TRACKER}>
          Balance Tracker
        </Link>
        <Link className="navbar-item" to={ROUTES.BLOG}>
          Blog &amp; Recipes
        </Link>
      </div>
      <div className="navbar-end has-text-centered">
        <Link className="navbar-item" to={ROUTES.ACCOUNT}>
          Account
        </Link>
        {!!authUser.roles[ROLES.ADMIN] && (
          <Link className="navbar-item" to={ROUTES.ADMIN}>
            Admin
          </Link>
        )}
        <Link className="navbar-item" to={ROUTES.SIGN_OUT}>
          Sign out
        </Link>
      </div>
    </>
  );
};

const NavigationNonAuth = () => {
  return (
    <>
      <div className="navbar-start has-text-centered">{standardLinks}</div>
      <div className="navbar-end has-text-centered">
        <Link className="navbar-item" to={ROUTES.SIGN_IN}>
          Sign In
        </Link>
      </div>
    </>
  );
};

export default Navbar;
