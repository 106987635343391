import * as React from "react";
// import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import Modal from "react-modal";
import { isMobile, isTablet } from "react-device-detect";
import { useLocation } from "@reach/router";
import { FaSpinner } from "@react-icons/all-files/fa/FaSpinner";
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import useAuthUser from "../Session/useAuthUser";
import { useFirebase } from "../Firebase/context";
import SuccessImage from "../../../static/img/lets_do_this.jpg";
import { PRICING } from "../../constants/pricing";
import { useScrollPosition } from "../../hooks/useScrollPosition";

const isTouch = isMobile || isTablet;

const EmailPromoSchema = Yup.object().shape({
  email_address: Yup.string()
    .email("Invalid email address")
    .required("Required"),
});

const customStyles = {
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  width: !isTouch ? "30%" : "100%",
  backgroundColor: "#000000",
};

const EnterEmailModal = ({ open, onCancel, onSuccess }) => {
  const [values, setValues] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [result, setResult] = React.useState(null);

  const execSubmit = React.useCallback((values) => {
    setLoading(true);
    const { email_address } = values;

    fetch("/.netlify/functions/newsletter-subscribe", {
      method: "post",
      body: JSON.stringify({ email_address, EmailPromo: true }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then(
        (response) => {
          setResult(true);
          // trackCustomEvent({
          //   category: "Trial Form",
          //   action: "Submit",
          //   label: "Email Popup",
          // });
        },
        (error) => {
          setResult(false);
        }
      )
      .then(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (values) {
      execSubmit(values);
    }
  }, [execSubmit, values]);

  return (
    <Modal
      isOpen={open}
      contentLabel="Promotional Dialog"
      style={{ overlay: { zIndex: 31 }, content: customStyles }}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onCancel}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",

          flex: 1,
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", right: 0, top: 0 }}>
          <button
            className="icon-button"
            onClick={onCancel}
            aria-label="Dismiss free month offer"
          >
            <FaTimes aria-hidden="true" size={24} color="white" />
          </button>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h1
            className="title is-size-4 has-text-weight-bold is-bold-light has-text-centered"
            style={{ color: "#fff" }}
          >
            FREE TRIAL!
          </h1>
          {!!result && (
            <>
              <p className="promo-text">
                Your promo code is in your email ({values?.email_address})!
                Let's do this!
              </p>
              <div
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  className="promo-success-image"
                  src={SuccessImage}
                  alt={"Excited Kristie"}
                />
              </div>
              <button
                className="fancy guest-promo-submit"
                type="button"
                onClick={onSuccess}
              >
                Close
              </button>
            </>
          )}
          {result === false && (
            <>
              <p className="promo-text">
                Something went wrong, please try again later.
              </p>
              <button
                className="fancy guest-promo-submit"
                type="button"
                onClick={onCancel}
              >
                Close
              </button>
            </>
          )}
          {!!loading && (
            <>
              <p className="promo-text">Get excited!!!</p>{" "}
              <FaSpinner
                className="fa-spin"
                size={40}
                style={{ marginRight: "1rem", verticalAlign: "middle" }}
              />{" "}
              <div
                style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  className="promo-success-image"
                  src={SuccessImage}
                  alt={"Excited Kristie"}
                />
              </div>
            </>
          )}
          {!loading && result === null && (
            <>
              <p className="promo-text">
                Enter your email to unlock your first month FREE + continue for
                just ${PRICING.MONTHLY}/month!
              </p>

              <Formik
                initialValues={{
                  email_address: "",
                }}
                validationSchema={EmailPromoSchema}
                onSubmit={(values) => {
                  setValues({ ...values });
                }}
                render={() => (
                  <Form noValidate={true}>
                    <label htmlFor="guestEmail">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                        }}
                      >
                        <strong style={{ color: "#fff" }}>Email</strong>
                        <ErrorMessage
                          name="email_address"
                          component="span"
                          className="field-error"
                        />
                      </div>
                    </label>
                    <Field
                      id="guestEmail"
                      aria-required="true"
                      name="email_address"
                      placeholder="email@address.com"
                      type="email"
                    />
                    <button
                      className="fancy guest-promo-submit"
                      type="submit"
                      onClick={() => console.log("submit pressed")}
                    >
                      Let's Do This
                    </button>
                  </Form>
                )}
              />

              <p className="meta disclaimer">
                By signing up, you agree to Kristielengel.com's Privacy Policy
                and Terms of Service, and to receive Kristielengel.com offers
                and promotions. We promise to never sell your personal
                information. You may unsubscribe from our emails at any time.
              </p>

              <button
                className="no-promo"
                onClick={onCancel}
                style={{ color: "#fff" }}
              >
                No thanks!
              </button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default function GuestPromoSignUp() {
  const firebase = useFirebase();
  const authUser = useAuthUser();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [hide, setHide] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [hasNotShown, setHasNotShown] = React.useState(false);

  const scrollDelta = React.useRef(0);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const delta = Math.abs(currPos.y - prevPos.y);
      scrollDelta.current += delta;
      if (scrollDelta.current >= 350 && !open && !hasNotShown) {
        setOpen(true);
        setHasNotShown(true);
      }
    },
    [scrollDelta, open, hasNotShown],
    null,
    true
  );

  // TODO: put back root here eventually when landing page is done
  const enabledRoutes = /\/blog\/?.*|\/signup\/$/gm;

  if (!firebase) return null;
  if (authUser) return null;
  if (success) return null;
  if (!location.pathname.match(enabledRoutes)) return null;

  if (hide) {
    return (
      <div>
        <EnterEmailModal open={open} onCancel={() => setOpen(false)} />
      </div>
    );
  }

  return (
    <div className="guest-promo-box">
      <button
        className="free-month-btn"
        onClick={() => setOpen(true)}
        aria-label="Click for a FREE month!"
      >
        <span aria-hidden="true">
          Click for a <strong style={{ color: "#fff" }}>FREE</strong> month!
        </span>
      </button>
      <button
        onClick={() => {
          setOpen(true);
          setHide(true);
        }}
        className="close-button"
        aria-label="Dismiss free month offer"
      >
        <FaTimes
          style={{ verticalAlign: "middle", color: "#fff" }}
          aria-hidden="true"
        />
      </button>
      <EnterEmailModal
        open={open}
        onSuccess={() => setSuccess(true)}
        onCancel={() => setOpen(false)}
      />
    </div>
  );
}
