export const PRICING = {
  MONTHLY: "29.99",
  TRI_MONTHLY: "69.99",
  SEMI_ANNUALLY: "99.99",
};

export const PRICES = {
  8: Number(PRICING.MONTHLY),
  9: Number(PRICING.TRI_MONTHLY),
  10: Number(PRICING.SEMI_ANNUALLY),
};
